import React from 'react'
import styled from '@emotion/styled'

import PressBanner from './PressBanner'
import PressCard from './PressCard'
import SEO from '../../components/SEO'
import Layout from '../../components/Layout'
import {
  laptopContainerStyle,
  mobileContainerStyle
} from '../../components/Common'
import { H1M } from '../../styleElements'
import usePressPage from '../../queries/usePressPage'
import mq from '../../utils/mediaQuery'

const PressPage = () => {
  const nodes = usePressPage()

  const PRESS_COLLECTION = [
    {
      name: 'DIGITAL TRENDS',
      img: nodes.pressDigitaltrends,
      desc:
        'Nealy a third use smartphones and tablets for creative projects, study shows',
      link:
        'https://www.digitaltrends.com/photography/mobile-creatives-videoblocks-study/'
    },
    {
      name: 'CNET',
      img: nodes.pressCnet,
      desc:
        'Nealy a third use smartphones and tablets for creative projects, study shows',
      link:
        'https://download.cnet.com/news/top-3-photo-collage-apps-for-android/'
    },
    {
      name: 'USA Today',
      img: nodes.pressUsatoday,
      desc: 'Pic Collage: Free app spices up your family photos',
      link:
        'https://www.usatoday.com/story/tech/2013/01/04/pic-collage-photos-app-insider/1809211/'
    },
    {
      name: 'VentureBeat',
      img: nodes.pressVenturebeat,
      desc:
        'MediaSpike weaves movie ads as product placements into the PicCollage photo app',
      link:
        'https://venturebeat.com/2014/10/22/mediaspike-weaves-movie-ads-as-product-placements-into-the-piccollage-photo-app/'
    },
    {
      name: 'San Jose Mercury News',
      img: nodes.pressSanjose,
      desc: 'Around the Web: PicCollage',
      link: 'https://www.mercurynews.com/2012/01/25/around-the-web-piccollage/'
    },
    {
      name: 'LA Times',
      img: nodes.pressLatimes,
      desc: 'Web Buzz: PicCollage helps organize photos on iPhone, iPad',
      link:
        'https://www.latimes.com/style/la-xpm-2012-jan-08-la-tr-webbuzz-20120108-story.html'
    }
  ]

  return (
    <Layout>
      <SEO title="Press" />
      <PressBanner />
      <InfoArea>
        <DescriptionText>
          <TitleContainer>
            <H1M>PRESS</H1M>
          </TitleContainer>
        </DescriptionText>
      </InfoArea>
      <LinkArea>
        <LinkContainer>
          {PRESS_COLLECTION.map(press => (
            <PressCard key={press.name} {...press} />
          ))}
        </LinkContainer>
      </LinkArea>
    </Layout>
  )
}

const InfoArea = styled.section`
  ${mobileContainerStyle}
  background-color: ${props => props.theme.color.white};
  padding-top: 20px;
  padding-bottom: 40px;
`

const DescriptionText = styled.div`
  ${mq.md} {
    max-width: 780px;
    margin: 0 auto;
    width: 80%;
    position: relative;
  }
`

const TitleContainer = styled.header`
  ${mq.md} {
    position: absolute;
    transform: rotate(90deg);
    left: -130px;
    bottom: -35px;
    z-index: 1;
  }
`

const LinkArea = styled.section`
  ${mobileContainerStyle}
  background-color: ${props => props.theme.color.white};
  padding-bottom: 80px;

  ${mq.md} {
    padding: 80px 20px 100px;
  }
`

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${mq.md} {
    ${laptopContainerStyle}
    max-width: 1140px;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`

export default PressPage
